import { render, staticRenderFns } from "./FindPatent.vue?vue&type=template&id=fa3dd300&scoped=true&"
import script from "./FindPatent.vue?vue&type=script&lang=js&"
export * from "./FindPatent.vue?vue&type=script&lang=js&"
import style0 from "./FindPatent.vue?vue&type=style&index=0&id=fa3dd300&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa3dd300",
  null
  
)

export default component.exports